exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-lulu-mall-in-india-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/lulu-mall-in-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-lulu-mall-in-india-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-rajiv-gandhi-international-airport-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/guides/rajiv-gandhi-international-airport/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-guides-rajiv-gandhi-international-airport-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-modular-kitchen-designs-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/homes/modular-kitchen-designs/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-homes-modular-kitchen-designs-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-asparagus-plant-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/asparagus-plant/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-asparagus-plant-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-money-plant-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/money-plant/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-money-plant-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-passiflora-caerulea-flower-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/passiflora-caerulea-flower/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-passiflora-caerulea-flower-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-simple-rangoli-design-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/lifestyle/simple-rangoli-design/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-lifestyle-simple-rangoli-design-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-what-is-cloud-computing-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/what-is-cloud-computing/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-what-is-cloud-computing-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-what-is-seo-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/technology/what-is-seo/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-technology-what-is-seo-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-navratri-colours-2024-significance-day-wise-list-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/navratri-colours-2024-significance-day-wise-list/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-navratri-colours-2024-significance-day-wise-list-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-noel-tata-new-chairman-of-tata-trusts-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/noel-tata-new-chairman-of-tata-trusts/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-noel-tata-new-chairman-of-tata-trusts-index-mdx" */),
  "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-ratan-tata-leadership-qualities-index-mdx": () => import("./../../../src/templates/blog-details-layout.jsx?__contentFilePath=/vercel/path0/content/blog/trends/ratan-tata-leadership-qualities/index.mdx" /* webpackChunkName: "component---src-templates-blog-details-layout-jsx-content-file-path-content-blog-trends-ratan-tata-leadership-qualities-index-mdx" */),
  "component---src-templates-blog-homepage-layout-jsx": () => import("./../../../src/templates/blog-homepage-layout.jsx" /* webpackChunkName: "component---src-templates-blog-homepage-layout-jsx" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */)
}

